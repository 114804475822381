body {
    background-color: #dedede !important;
    position: relative !important;
}

.markdown {
    font-size: smaller;
}

.gallery {
    width: 66%;
    margin-right: 10px;
}

.announcement {
    display: inline-block;
    height: 100%;
    text-align: center;
    width: 100%;
    background: black;
    color: white;
    font-family: Arial, sans;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    font-size: 0.8em;
}

.announcement a {
    color: #ccc;
}

.announcement a:hover {
    color: red;
}

